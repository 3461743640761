import Head from 'next/head';
import Link from 'next/link';
import { Image } from '@apmg/mimas';
import { CollectionItem } from 'models/Collection/CollectionType';
import { linkByTypeAs } from 'utils/cjsutils';

interface PersonCollectionItem extends CollectionItem {
  lastName: string;
}

const StaffTemplate = (staff: any) => {
  return (
    <div className="container">
      <Head>
        <link href="/css/pages/staff.css" rel="stylesheet" />
      </Head>
      <div className="staff-main">
        <ul className="grid">
          {staff &&
            staff.data.department.results.items
              .filter((item: CollectionItem) => item.resourceType == 'profile')
              .sort((a: PersonCollectionItem, b: PersonCollectionItem) => {
                if (a.lastName < b.lastName) return -1;
                if (a.lastName > b.lastName) return 1;
                return 0;
              })
              .map((item: CollectionItem) => {
                return (
                  <li key={item.title}>
                    <div>
                      <div className="staff-image">
                        {item.primaryVisuals?.thumbnail?.aspect_ratios
                          ?.normal ? (
                          <Link href={linkByTypeAs(item)} passHref>
                            <Image
                              image={item.primaryVisuals.thumbnail}
                              aspectRaio="normal"
                              sizes="(min-width: 72em) 10vw,  80vw"
                            />
                          </Link>
                        ) : (
                          <Link href={linkByTypeAs(item)} passHref>
                            <img
                              src="/img/no-profile-picture.png"
                              alt="placeholder"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <div className="staff-content">
                        <Link
                          href={linkByTypeAs(item)}
                          passHref
                          className="type-lg"
                        >
                          <div>{item.title}</div>
                        </Link>
                        <Link
                          href={linkByTypeAs(item)}
                          className="type-md staff-jobtitle"
                          passHref
                        >
                          {item.jobTitle}
                        </Link>
                      </div>
                    </div>
                  </li>
                );
              })}
        </ul>
        {/* {staff && (
          <div className="collection_pagination">
            <div className="pagination-container">
              <Pagination
                hasFirstAndLast={true}
                inclusiveFirstLast={false}
                buffer={1}
                hrefPrefix={`[...slug]`}
                asPrefix={`mpr-newsroom`}
                currentPage={staff.data.department.results.currentPage}
                totalPages={staff.data.department.results.totalPages}
                middleSymbol="of"
                firstSymbol={staff.data.department.results.currentPage}
                nextSymbol={
                  <>
                    <span>Next</span>
                    <IconChevron
                      direction="right"
                      color="currentColor"
                      decorative
                    />
                  </>
                }
                prevSymbol={
                  <>
                    <IconChevron
                      direction="left"
                      color="currentColor"
                      decorative
                    />
                    <span aria-label="Previous">Prev</span>
                  </>
                }
                lastSymbol={staff.data.department.results.totalPages}
                prevNextClass="btn btn-primary btn-pagination no-text-transform"
              />
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default StaffTemplate;
