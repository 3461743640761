import React from 'react';
import Head from 'next/head';
import Link from 'next/link';

export default function fourOfour() {
  return (
    <>
      <Head>
        <link href="/css/pages/story.css" rel="stylesheet" />
      </Head>
      <div className="story-content-grid">
        <div className="container grid grid-3col grid-3-1">
          <main className="grid-primary story-content">
            <h1>404</h1>
            <p style={{ marginTop: '0.5rem' }}>
              We&lsquo;re sorry. We didn&lsquo;t find what you where looking
              for. Please try the <Link href="/search">search</Link> or return
              to the <Link href="/">home page</Link>.
            </p>
          </main>
        </div>
      </div>
    </>
  );
}
