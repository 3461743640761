import React, { useEffect } from 'react';
import Router from 'next/router';
import ErrorPage from 'next/error';
import fetcher from '../utils/fetcher';
import query from '../endpoints/Collection/variable.gql';
import cQuery from '../endpoints/Collection/col.gql';
import pQuery from '../endpoints/Page/page.gql';
import Page from '../endpoints/Page/Page';
import Collection from '../endpoints/Collection/Collection';
import ContentGrid from '../grids/ContentGrid';
import Sidebar from '../components/Sidebar/Sidebar';
import { isNumeric, parseEmbeddedAssets } from '../utils/utils';
import adCleanup from '../utils/adCleanup';
import { useRouter } from 'next/router';
import StaffTemplate from '../endpoints/Staff/staffTemplate';
import Four0Four from 'components/Four0Four/Four0Four';
import { getServerSession } from 'next-auth';
import { authOptions } from 'pages/api/auth/[...nextauth]';

const VariablePage = (obj) => {
  const { data, slug, type, pageNum } = obj;
  const router = useRouter();

  useEffect(() => {
    if (window.location.href === 'politics/election2022') {
      Router.push('/election');
    }
    if (window.location.href === 'weather-live') {
      const scrpt = document.createElement('script');
      scrpt.src = `https://widgets.media.weather.com/wxwidget.loader.js?cid=431513429&apmId=${Date.now()}`;
      document.getElementsByTagName('head')[0].appendChild(scrpt);
      if (window.wxWidgets) window.wxWidgets.parse();
    }
  }, []);

  let pageTopic;

  if (obj.errorCode == 404) return <Four0Four />;
  if (obj.errorCode) return <ErrorPage statusCode={obj.errorCode} />;
  if (type === 'collection') {
    // to be removed after 2022 election

    let layoutTemplate;
    if (data) {
      layoutTemplate = data.collection.templateName;
    }

    if (layoutTemplate == 'default' && router.asPath.includes('mpr-newsroom')) {
      return <StaffTemplate data={data} pageNum={pageNum} slug={slug} />;
    }

    return (
      <ContentGrid
        h1Text={data.collection.title}
        sidebar={<Sidebar pageTopic={pageTopic} />}
      >
        <div id="mainContent"></div>
        <div className="content">
          <Collection data={data} />
        </div>
      </ContentGrid>
    );
  }
  if (type === 'page') {
    return (
      <ContentGrid sidebar={<Sidebar pageTopic={pageTopic} />}>
        <Page data={data} />
      </ContentGrid>
    );
  }

  return <Four0Four />;
};

export const getServerSideProps = async ({ query: { slug }, req, res }) => {
  let data = null,
    type = null,
    errorCode = null,
    pageNum = 1,
    layout = 'default',
    pageSize = 10;

  if (isNumeric(slug[slug.length - 1])) {
    pageNum = parseInt(slug.pop());
  }
  if (res.req.url.includes('mpr-newsroom')) {
    pageSize = 20;
  }

  const getCollectionData = async () => {
    let data = null;

    await fetcher(cQuery, {
      contentAreaSlug: process.env.CONTENT_AREA_SLUG,
      slug: slug.join('/'),
      pageNum: pageNum,
      pageSize: pageSize,
    })
      .then((result) => {
        data = result;
        if (data?.collection?.embeddedAssets) {
          parseEmbeddedAssets(data.collection.embeddedAssets);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return data;
  };

  const getPageData = async () => {
    let data = null;
    await fetcher(pQuery, {
      contentAreaSlug: process.env.CONTENT_AREA_SLUG,
      slug: slug.join('/'),
    })
      .then((result) => {
        data = result;
        if (data?.page?.embeddedAssets) {
          parseEmbeddedAssets(data.page.embeddedAssets);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return data;
  };

  await fetcher(query, {
    contentAreaSlug: process.env.CONTENT_AREA_SLUG,
    slug: slug.join('/'),
  })
    .then(async (result) => {
      if (res) {
        res.setHeader('Cache-Control', 'public, max-age=60');
      }
      if (!result.content) {
        if (res) {
          res.statusCode = 404;
        }
        errorCode = 404;
        return { props: { errorCode } };
      }
      type = result.content.resourceType;
      if (type === 'collection') {
        data = await getCollectionData();
        layout = data?.collection?.templateName;
      }
      if (type === 'page') {
        data = await getPageData();
        layout = data?.page?.primaryCollection?.templateName || 'default';
      }
    })
    .catch((err) => {
      console.error(err);
      res.statusCode = 500;
      errorCode = 500;
    });

  adCleanup();
  const session = await getServerSession(req, res, authOptions);

  if (type === 'story') {
    return {
      redirect: {
        destination: `/story/${slug?.join('/')}`,
        permanent: true,
      },
    };
  }

  if (!data) {
    errorCode = 404;
    res.statusCode = 404;
  }

  return {
    props: {
      data,
      type,
      pageNum,
      errorCode,
      layout,
      session,
      resourceType: data?.collection?.resourceType || 'page',
    },
  };
};

export default VariablePage;
